import React from 'react';
import Address from '../Address';

import Layout from './layout';

export default function Contact() {
  return (
    <Layout title="Get <span>in touch</span>">
      <Address />
    </Layout>
  );
}
